<template>
  <v-container class="pa-5 mb-10" fluid>
    <v-row style="width: 100%">
      <v-col cols="12">
        <v-card style="background: #f5f5f5">
          <v-card-title class="pt-10 pl-10">
            Product Report
            <v-spacer></v-spacer>
            <!-- <v-btn @click="download" text>
              <v-icon class="pr-5" large color="black">mdi-download </v-icon>
            </v-btn> -->
            <!-- <v-btn text @click="printData">
              <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
            </v-btn> -->
          </v-card-title>
          <v-card style="background: #f5f5f5">
            <v-row>
              <v-col cols="6">
                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  color="primary"
                  grow
                >
                  <v-tab v-for="item in items" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <div id="dataapp">
                  <v-data-table
                    :headers="headers"
                    :items="Sales"
                    :search="search"
                    sort-by="id"
                    flat
                    item-key="id"
                    class="elevation-1"
                    style="background: #f5f5f5; font-size: 10em"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    show-expand
                  >
                    <template v-slot:top>
                      <v-toolbar elevation="2" style="background: #f5f5f5">
                        <v-toolbar-title
                          style="margin-left: -14px"
                          class="mt-2 mb-2 ml-2"
                          >Approved Product
                        </v-toolbar-title>

                        <v-spacer></v-spacer>
                        <div class="pr-5 pb-5 pt-5">
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            filled
                            rounded
                            dense
                            hide-details
                          ></v-text-field>
                        </div>
                        <v-switch
                          v-model="singleExpand"
                          label="Single expand"
                          class="pt-5 mr-5"
                        ></v-switch>
                      </v-toolbar>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td
                        :colspan="headers.length"
                        v-for="(product, index) in item.specifications"
                        :key="index"
                        class="d-flex flex-row"
                      >
                        <v-col
                          cols="12"
                          class="d-flex flex-row justify-space-around"
                        >
                          <b> {{ index + 1 }} .</b>
                          <b>Color : </b> {{ product.color }} <br />
                          <b> Size : </b> {{ product.size }}<br />
                          <b>Quantity : </b> {{ product.quantity }}<br />
                          <b> Purchase Price : </b> {{ product.purchase_price
                          }}<br />
                          <b> Selling Price : </b> {{ product.new_price }}<br />
                        </v-col>
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-data-table
                  :headers="headersweek"
                  :items="CancelledProduct"
                  :search="searchweek"
                  sort-by="id"
                  class="elevation-1"
                  style="background: #f5f5f5; font-size: 10em"
                  ref="printTableInfo"
                  hide-default-footer
                  :single-expand="singleExpand2"
                  :expanded.sync="expanded"
                  show-expand
                >
                  <template v-slot:top>
                    <v-toolbar elevation="2" style="background: #f5f5f5">
                      <v-toolbar-title
                        style="margin-left: -14px"
                        class="mt-2 mb-2 ml-2"
                      >
                        Cancelled Product
                      </v-toolbar-title>

                      <v-spacer></v-spacer>
                      <div class="pr-5 pb-5 pt-5">
                        <v-text-field
                          v-model="searchweek"
                          append-icon="mdi-magnify"
                          label="Search"
                          filled
                          rounded
                          dense
                          hide-details
                        ></v-text-field>
                      </div>
                      <v-switch
                        v-model="singleExpand2"
                        label="Single expand"
                        class="pt-5 mr-5"
                      ></v-switch>
                    </v-toolbar>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td
                      :colspan="headers.length"
                      v-for="(product, index) in item.specifications"
                      :key="index"
                      class="d-flex flex-row"
                    >
                      <v-col
                        cols="12"
                        class="d-flex flex-row justify-space-around"
                      >
                        <b> {{ index + 1 }} .</b>
                        <b>Color : </b> {{ product.color }} <br />
                        <b> Size : </b> {{ product.size }}<br />
                        <b>Quantity : </b> {{ product.quantity }}<br />
                        <b> Purchase Price : </b> {{ product.purchase_price
                        }}<br />
                        <b> Selling Price : </b> {{ product.new_price }}<br />
                      </v-col>
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item>
                <v-data-table
                  :headers="pendin_header"
                  :items="pendindarray"
                  :search="pendingsearch"
                  sort-by="id"
                  class="elevation-1"
                  style="background: #f5f5f5; font-size: 10em"
                  ref="printTableInfo"
                  hide-default-footer
                  :single-expand="singleExpand2"
                  :expanded.sync="expanded"
                  show-expand
                >
                  <template v-slot:top>
                    <v-toolbar elevation="2" style="background: #f5f5f5">
                      <v-toolbar-title
                        style="margin-left: -14px"
                        class="mt-2 mb-2 ml-2"
                      >
                        Pending Product
                      </v-toolbar-title>

                      <v-spacer></v-spacer>
                      <div class="pr-5 pb-5 pt-5">
                        <v-text-field
                          v-model="pendingsearch"
                          append-icon="mdi-magnify"
                          label="Search"
                          filled
                          rounded
                          dense
                          hide-details
                        ></v-text-field>
                      </div>
                      <v-switch
                        v-model="singleExpand3"
                        label="Single expand"
                        class="pt-5 mr-5"
                      ></v-switch>
                    </v-toolbar>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td
                      :colspan="headers.length"
                      v-for="(product, index) in item.specifications"
                      :key="index"
                      class="d-flex flex-row"
                    >
                      <v-col
                        cols="12"
                        class="d-flex flex-row justify-space-around"
                      >
                        <b> {{ index + 1 }} .</b>
                        <b>Color : </b> {{ product.color }} <br />
                        <b> Size : </b> {{ product.size }}<br />
                        <b> Variant : </b> {{ product.weight_unit }}<br />
                        <b>Quantity : </b> {{ product.quantity }}<br />
                        <b> Purchase Price : </b> {{ product.purchase_price
                        }}<br />
                        <b> Selling Price : </b> {{ product.new_price }}<br />
                      </v-col>
                    </td>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" outlined centered color="black">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    snackbar: "",
    singleExpand: false,
    singleExpand2: false,
    search: "",
    searchweek: "",
    pendingsearch: "",
    headers: [
      { text: "Approval Date", value: "approved_date", sortable: false },
      { text: "Product Name", value: "title", sortable: false },
      { text: "Brand", value: "brand", sortable: false },
      { text: "Total Quantity", value: "total_quantity", sortable: false },
      { text: "Menufectured In ", value: "origin", sortable: false },
      { text: "Shiping From", value: "shipping_country", sortable: false },
      { text: "", value: "data-table-expand" },
    ],

    headersweek: [
      { text: "Cancellation Date", value: "approved_date", sortable: false },
      { text: "Product Name", value: "product_title", sortable: false },
      { text: "Brand", value: "brand", sortable: false },
      { text: "Total Quantity", value: "total_quantity", sortable: false },
      { text: "Menufectured In ", value: "unit_price", sortable: false },
      { text: "Shiping From", value: "product_price", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    pendin_header: [
      //{ text: "Cancelled Date", value: "approved_date", sortable: false },
      { text: "Product Name", value: "product_title", sortable: false },
      { text: "Brand", value: "brand", sortable: false },
      { text: "Total Quantity", value: "total_quantity", sortable: false },
      { text: "Menufectured In ", value: "unit_price", sortable: false },
      { text: "Shiping From", value: "product_price", sortable: false },
      { text: "", value: "data-table-expand" },
    ],

    Sales: [],
    contact: {},
    Salesweek: [],
    specificationinfo: [],
    CancelledProduct: [],
    pendindarray: [],

    text: "",
    snackbar: false,
    tab: null,
    items: ["Approved Product", " Cancelled Product", "Pending Product"],
  }),

  methods: {
    initialize() {
      axios
        .get(`product/seller_approved_products/${this.$route.query.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.Sales = response.data.data;
            this.specificationinfo = response.data.data[0].specifications;
          }
        });
    },

    getCancel() {
      axios
        .get(`product/seller_cancelled_products/${this.$route.query.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.CancelledProduct = response.data.data;
            this.specificationinfo = response.data.data[0].specifications;
          }
        });
    },

    getpending() {
      axios
        .get(`product/seller_pending_products/${this.$route.query.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.pendindarray = response.data.data;
            this.specificationinfo = response.data.data[0].specifications;
          }
        });
    },

    // sumField(key) {
    //   // sum data in give key (property)
    //   return this.Sales.reduce((a, b) => a + (b[key] || 0), 0);
    // },

    // printData() {
    //   var divToPrint = document.getElementById("dataapp");
    

    //   var style = "<style>";
    //   style = style + "table {width: 100%;font: 17px Calibri;}";
    //   style =
    //     style +
    //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    //   style = style + "padding: 2px 3px;text-align: center;}";
    //   style = style + "</style>";

    //   var win = window.open("", "", "height=1900,width=1900");
    //   win.document.write(style); //  add the style.
    //   win.document.write(divToPrint.outerHTML);
    //   win.document.close();
    //   win.print();
    // },

    fromdate() {},
    seereport() {},
    todate() {},
  },
  mounted() {
    this.initialize();
    this.getpending();
    this.getCancel();
  },
};
</script>




<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>